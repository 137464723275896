import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { SectionHeader, DescriptionCta } from 'components/molecules';
import { CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const Main = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <DescriptionCta
        title="Field Application Scientist"
        subtitle="Ahemdabad· Full time"
        primaryCta={
          <Button variant="outlined" color="primary" size="large">
            Refer a friend
          </Button>
        }
        secondaryCta={
          <Button variant="contained" color="primary" size="large">
            Apply now
          </Button>
        }
        align={'left'}
        titleProps={{
          variant: 'h3',
          className: classes.title,
          color: 'textPrimary',
        }}
        subtitleProps={{
          color: 'textPrimary',
        }}
      />
      <Divider className={classes.divider} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <SectionHeader
            title="Responsibilities"

            align="left"
            data-aos="fade-up"
            titleProps={{
              className: classes.title,
              color: 'textPrimary',
            }}
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />



Coordinating new installations, including facility inspections, instrument validation, and customer training.<br/><br/>

Troubleshooting issues related to the user’s technical skills, biochemistry/assay design, experimental design, software, and instruments.<br/><br/>
Developing and maintaining positive relationships with customers in assigned territory.<br/><br/>
Helping to maintain and increase product usage, and supporting sales efforts through technical presentations and demonstrations.<br/><br/>
Acting as a key contributor of customer feedback to marketing and product development teams.<br/><br/>
Developing and improving key company processes that support our mission of building a world-class customer support organization.<br/><br/>
All listed tasks and responsibilities are deemed as essential functions to this position; however, business conditions may require reasonable accommodations for additional task and responsibilities.<br/><br/>

          <List className={classes.list}>
            {data.map((item, index) => (
              <ListItem disableGutters key={index} data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    src="https://ik.imagekit.io/j6phbjneuop/iconfinder_1814079_approve_check_ok_yes_icon_512px_uLSoNbZvP.png"
                    className={classes.checkBox}
                  />
                </ListItemAvatar>
                <Typography variant="body1" color="textPrimary">
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
         
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <CardBase withShadow className={classes.cardHighlighted}>
              <SectionHeader
                  title="Know about company"
                  subtitle="Checkout Our Story or signup for our newsletter"
                  ctaGroup={[<Button href = '/Ourstory'variant="contained">learn more</Button>]}
                  disableGutter
                  align="left"
                  titleProps={{
                    variant: 'h6',
                    className: classes.textWhite,
                  }}
                  subtitleProps={{
                    variant: 'body1',
                    className: classes.textWhite,
                  }}
                />
              </CardBase>
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <CardBase withShadow>
              <SectionHeader
                  title="Don't see a job for you?"
                  titleVariant="h6"
                  subtitle="Don't worry! Other job openings may interest you."
                  ctaGroup={[
                      <Link to = '/career-listing-minimal'>
                    <Button variant="contained" color="primary">
                      See other vacances
                    </Button>
                    </Link>
                  ]}
                  disableGutter
                  align="left"
                  subtitleProps={{
                    variant: 'body1',
                  }}
                />
              </CardBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Main.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Main;
