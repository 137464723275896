import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import Application from './Application';
import Main from './Main'








const jobRequirements = [
    'At least 2 years of biotech experience preferred',
    ' Prior field experience supporting customers using sequencing or array technologies preferred',
    "Demonstrated experience with next generation or high throughput sequencing sample prep technologies and protocols preferred",
    'Demonstrated understanding of commonly-used sequencing data analysis software applications preferred',
    'Demonstrated bioinformatics expertise and experience with handling and analyzing next generation sequencing or array data is a huge plus.',
    "Outstanding problem solving skills",
"Excellent communication skills in English",
"Good interpersonal skills",
"Must be willing to travel up to 50% of the time",
  
  ];
  

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
}));

const Jobc= () => {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <Main data={jobRequirements} />
       
      </Section>
      <SectionAlternate innerNarrowed>
      <Application />
      </SectionAlternate>
     
      <Divider />
    </div>
  );
};

export default Jobc;
