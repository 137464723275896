/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
 import React from 'react';
 import Fas from 'views/Fas';
 import Main from 'layouts/Main';
 import WithLayout from 'WithLayout';
 
 const Faspage = () => {
   return (
     <WithLayout
       component={Fas}
       layout={Main}
     />
   )
 };
 
 export default Faspage;